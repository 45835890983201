import "./App.css";
import { useState, useEffect } from "react";
import Header from "./components/Header/Header";
import "react-multi-carousel/lib/styles.css";
import Footer from "./components/Footer/Footer";
import Carousel from "./components/Carousel/Carousel";
import { BlurredDiv, Home } from "./App.styled";
import Services from "./components/Services/Services";
import AboutUs from "./components/AboutUs/AboutUs";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "./i18n";

function App() {
  const { t } = useTranslation();
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(true);
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <div
        style={{
          backgroundImage:
            "linear-gradient(black 0%, #262725 50%,  black 100%)",
        }}
        className={`fade-in-section ${fadeIn ? "is-visible" : ""}`}
      >
        <BlurredDiv />
        <Header />
        <Home id="home-section">
          <h1>{t("aboutOurWork")}</h1>
          {t("description")}
          <Carousel />
        </Home>
        <Services />
        <AboutUs />
        <Footer />
      </div>
    </I18nextProvider>
  );
}

export default App;
