const serbianTranslations = {
  description:
    "Dobrodošli u Radjenović frizerski atelje. U našem ateljeu, svaki detalj je pažljivo osmišljen da bi vaša poseta bila nezaboravno iskustvo. Sačekajte da vas naši stručnjaci upute kroz širok spektar usluga, uključujući moderno šišanje kao i besprekorno oblikovanje brade. U Radjenović Frizerskom Ateljeu, nudimo vam više od frizure - nudimo vam jedinstvenu priliku da se oslobodite, opustite i osetite se lepo.",
  aboutOurWork: "Radjenović frizerski atelje",
  home: "Početna",
  services: "Usluge",
  about: "O nama",
  sr: "RS",
  en: "EN",
  aboutUs: "O nama",
  whereToFindUs: "Gde nas pronaći?",
  aboutUsDescription:
    "Dobrodošli u Radjenović Frizerski Atelje, gde strast prema frizerskoj umetnosti susreće modernu eleganciju. Osnovan sa strašću i posvećenošću 2021. godine, naš atelje je mesto gde se tradicija susreće sa inovacijama. Bez obzira da li dolazite da osvežite svoj izgled ili da se prepustite luksuznom tretmanu, u Radjenović Frizerskom Ateljeu ćete uvek naći izvanrednu uslugu u sofisticiranom ambijentu. Dođite i otkrijte zašto smo više od frizerskog salona - mi smo mesto gde lepota dobija novi sjaj.",
  whereToFindUsDescription:
    "Radjenović Frizerski Atelje se nalazi na veoma pristupačnoj lokaciji na Karadjordjevom Trgu 17, Zrenjanin. Smestili smo se u živopisnom kraju, naš atelje nudi elegantnu i gostoljubivu atmosferu gde možete uživati u vrhunskim frizerskim uslugama. Bez obzira da li ste lokalni stanovnik ili posetilac ovog područja, pozivamo vas da doživite našu posvećenost u svakom šišanju i oblikovanju brade. Posetite nas na Karadjordjevom Trgu 17 i otkrijte mesto gde se lepota susreće sa umetnošću.",

  haircutTitle: "Šišanje",
  haircutDescription:
    "Uživajte u personalizovanoj usluzi šišanja koju ekskluzivno pruža naš posvećeni vlasnik. Bez obzira da li želite moderan stil, klasičnu frizuru ili pažljivo skraćivanje, naš vlasnik-stilista pruža izvanrednu zanatsku veštinu prilagođenu vašim željama. Koristeći vrhunske tehnike i kvalitetne proizvode, garantujemo da će vaša kosa dobiti najveću pažnju i negu. Uživajte u opuštenoj atmosferi i izađite sa frizurom koja savršeno odražava vašu individualnost.",
  beardTitle: "Brada",
  beardDescription:
    "Poboljšajte svoju rutinu održavanja brade uz stručno oblikovanje koje pruža naš posvećeni vlasnik. Specijalizovan za precizno oblikovanje i detaljno uređivanje, naš vlasnik-frizer garantuje da će vaša brada uvek izgledati uredno i doterano. Bez obzira da li preferirate uredan trim, moderni fade ili kompleksne dizajne, mi se prilagođavamo vašim jedinstvenim željama. Koristeći vrhunske proizvode, garantujemo uredan izgled koji ističe vaše karakteristike lica i ostavlja vas samouverenim i doteranim.",
};

export default serbianTranslations;
